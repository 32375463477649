<template>
<b-form>
    <b-row>
        <b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Name" label-class="fw-medium">
                <b-form-input placeholder="Name" v-model="form.business_name"></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group id="input2" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Email address" label-class="fw-medium">
                <b-form-input type="email" placeholder="Email" v-model="form.business_email"></b-form-input>
            </b-form-group>
        </b-col>
        <!--<b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Business Name" label-class="fw-medium">
                <b-form-input placeholder="Enter Business Name"></b-form-input>
            </b-form-group>
        </b-col>-->
        <b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Business Address" label-class="fw-medium">
                <b-form-input placeholder="Enter Business Address" v-model="form.address"></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Phone Number" label-class="fw-medium">
                <b-form-input placeholder="Phone" v-model="form.business_phone"></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Contact Person" label-class="fw-medium">
                <b-form-input placeholder="Contact Person" v-model="form.contact_person"></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group id="input1" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Locations" label-class="fw-medium">
                <b-form-select v-model="form.location_id" :options="locations"></b-form-select>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Avatar" label-class="fw-medium">
                <b-form-file ref="file-input" class="mb-2" v-model="form.avatar"></b-form-file>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group label-cols-sm="4" label-cols-lg="6" content-cols-sm content-cols-lg="6" label="Business Registration Certificate" label-class="fw-medium">
                <b-form-file ref="file-input" class="mb-2" v-model="form.certificate"></b-form-file>
            </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-center">
            <b-button variant="primary" pill class="d-flex align-items-center px-3" @click="submitProfile()">
                <feather type="send" class="feather-sm mr-2"></feather>
                Submit
            </b-button>
        </b-col>
    </b-row>
</b-form>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "Profile",
    components: {},
    data: () => ({
        form: {
            user_id: 1,
            location_id: 1,
            business_name: "None",
            business_email: "None",
            business_phone: "None",
            contact_person: "None",
            address: "None",
            avatar: "None",
            certificate: "None"
        },
        locations: [],
    }),
    mounted() {
        this.initComp();
    },
    methods: {
        ...mapActions(["getLocations", "saveProfile"]),
        initComp(){
            this.getLocations().then(res => {
                res.forEach(element => {
                   this.locations.push({ value: element.id, text: element.name }); 
                });
            })
        },
        submitProfile(){
            this.saveProfile(this.form).then(res => {
                if(res && 'status' in res && res.status >= 400){
                    this.$bvToast.toast(res.data, {
                        title: "Something Went Wrong",
                        variant: 'danger',
                        solid: true
                    })
                }
                else{
                    this.$bvToast.toast(res.data, {
                        title: "Success",
                        variant: 'success',
                        solid: true
                    })
                }
            })
        }
    }
};
</script>
